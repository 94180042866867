const usersData = [
  { distributor: '西友', shop: '店舗名001', registered: '2012/01/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Active'},
  { distributor: '西友', shop: '店舗名002', registered: '2012/02/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Banned'},
  { distributor: '西友', shop: '店舗名003', registered: '2012/02/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Inactive'},
  { distributor: '成城石井', shop: '店舗名001', registered: '2012/03/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Pending'},
  { distributor: '成城石井', shop: '店舗名002', registered: '2012/01/21', mail: 'distributor@distributors.com', authority: '小売', status: 'Active'},
  { distributor: 'イオン', shop: '店舗名001', registered: '2012/01/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Active'},
  { distributor: 'イオン', shop: '店舗名002', registered: '2012/02/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Banned', _classes: 'table-success'},
  { distributor: 'イオン', shop: '店舗名003', registered: '2012/02/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Inactive'},
  { distributor: '丸正', shop: '店舗名001', registered: '2012/03/01', mail: 'distributor@distributors.com', authority: '小売', status: 'Pending'},
]

export default usersData


